<template>
  <div>
    <div :class="$style.home">
      <BjSearch @refresh="onSearch" @init="init">
        <BjInput
          v-model="search.operator_name"
          allow-clear
          label="操作人员"
          v-bind="layout"
          placeholder="请输入操作人员姓名"
          @pressEnter="onSearch"
        />
        <BjValidationItem label="操作时间" v-bind="layout">
          <a-range-picker v-model="range" />
        </BjValidationItem>
        <BjSelect
          v-model="search.module"
          v-bind="layout"
          key-field="id"
          inner-search
          label="操作模块"
          placeholder="全部模块"
          value-field="id"
          show-all
          label-field="name"
          :options="moudleData"
        />
        <BjSelect
          v-model="search.operate"
          v-bind="layout"
          key-field="id"
          inner-search
          label="操作类型"
          placeholder="全部类型"
          value-field="id"
          show-all
          label-field="name"
          :options="optionData"
        />
      </BjSearch>
      <div :class="$style.table">
        <div :class="$style.title">日志列表</div>
        <a-table :columns="columns" :data-source="data" row-key="id" :pagination="false" :loading="loading">
          <template #user="item">
            <div>{{ item.master_name }}</div>
            <div>{{ item.master_phone }}</div>
          </template>
        </a-table>
        <div class="pagination">
          <a-pagination
            :current="page"
            show-size-changer
            :show-total="total => `共 ${total} 条`"
            :page-size.sync="pageSize"
            :total="total"
            @showSizeChange="onShowSizeChange"
            @change="onPageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { roleService } from '@/service'

const service = new roleService()

export default {
  name: 'Home',
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      search: {
        operator_name: null,
        start_time: null,
        end_time: null,
        module: '',
        operate: '',
      },
      moudleData: [],
      optionData: [],
      data: [],
      range: [],
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '操作人员',
          scopedSlots: {
            customRender: 'user',
          },
        },
        {
          title: '操作模块',
          dataIndex: 'module_name',
        },
        {
          title: '操作类型',
          dataIndex: 'operate_name',
        },
        {
          title: '操作人IP',
          dataIndex: 'ip',
        },
        {
          title: '操作时间',
          dataIndex: 'created_at',
        },
        {
          title: '操作内容',
          dataIndex: 'operate_content',
        },
      ]
    },
  },
  created() {
    this.getMoudle()
    this.getOption()
    this.onSearch()
  },
  methods: {
    async onSearch() {
      this.loading = true
      if (this.range.length) {
        this.search.start_time = this.range[0].format('YYYY-MM-DD') + ' 00:00:00'
        this.search.end_time = this.range[1].format('YYYY-MM-DD') + ' 23:59:59'
      }
      const { data } = await service.getLog({ ...this.search, page: this.page, page_size: this.pageSize })
      this.data = data.record
      this.total = data.total
      this.loading = false
    },
    async getOption() {
      const { data } = await service.getOption()
      this.optionData = [{ name: '全部类型', id: '' }].concat(data)
    },
    async getMoudle() {
      const { data } = await service.getModule()
      this.moudleData = [{ name: '全部模块', id: '' }].concat(data)
    },
    init() {
      this.search = {
        operator_name: null,
        start_time: null,
        end_time: null,
        module: '',
        operate: '',
      }
      this.range = []
      this.page = 1
      this.onSearch()
    },
    onPageChange(page) {
      this.page = page
      this.onSearch()
    },
    onShowSizeChange() {
      this.page = 1
      this.onSearch()
    },
  },
}
</script>

<style lang="less" module>
.home {
  .table {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
    min-height: calc(100vh - 252px);

    .title {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}
</style>
